import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import InfoIcon from '@mui/icons-material/Info'
import WarningIcon from '@mui/icons-material/Warning'
import { CircularProgress } from '@mui/material'
import Fade from '@mui/material/Fade'
import Modal from '@mui/material/Modal'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Asset, Plant } from '../../models/Types_Interfaces'
import {
	DEFAULT,
	GREEN,
	ORANGE,
	RED,
	YELLOW
} from '../../Utils/UtilFunctions/constant'
import {
	getFormatedDate,
	getTimeZoneTimeStamp
} from '../../Utils/UtilFunctions/timeZoneFormatter'
import AGRAlert from './AGRAlertPopup'
import AirLeaksAlert from './AirLeaksAlert'
import AsepticAlert from './AscepticAlertPopup'
import AsepticCCPAlert from './AsepticCCPAlertPopup'
import AsepticCIPAlert from './AsepticCIPAlertPopup'
import { ReactComponent as DisconnectedIcon } from './disconnectedIcon.svg'
import DowmtimeAlertPopup from './DowmtimeAlertPopup'
import InjectionAnalyticsPopUp from './InjectionAnalyticsPopUp'
import RunHoursAlert from './RunHoursAlertPopup'
import UtilitiesAlert from './UtilitiesAlertPopup'
import { checkTicketTypeIsLGV } from '../../pages/maintainence/ticket-details/utilis/commonFunctions'
import LGVPopUp from './LGVPoup'

function Alert(props: any) {
	const ticketsList = useSelector((state: any) => state.ticketsList)
	const plantsList = useSelector((state: any) => state.plantsList)
	const levelsList = useSelector((state: any) => state.levelsList)
	const messagesList = useSelector((state: any) => state.messagesList)
	const usersList = useSelector((state: any) => state.usersList)

	const runHoursSubType = [
		'Operating Hours 50%',
		'Operating Hours 85%',
		'Operating Hours 100%',
		'Operating Hours 120%'
	]

	const navigate = useNavigate()

	return props.alert.alert_type === 'Utilities CBM/PdM' ? (
		<UtilitiesAlert
			open={
				usersList.currentUser.settings.web_notification?.receiveNotification &&
				props.open
			}
			// open={props.open}
			handleClose={props.handleClose}
			alert={props.alert}
			setOpen={props.setOpen}
		/>
	) : props.alert.alert_type === 'Aseptic - Batch Room CCP' ? (
		<AsepticAlert
			open={
				usersList.currentUser.settings.web_notification?.receiveNotification &&
				props.open
			}
			handleClose={props.handleClose}
			alert={props.alert}
			setOpen={props.setOpen}
		/>
	) : props.alert.alert_type === 'Aseptic - Process Room CCP' ? (
		<AsepticCCPAlert
			open={
				usersList.currentUser.settings.web_notification?.receiveNotification &&
				props.open
			}
			handleClose={props.handleClose}
			alert={props.alert}
			setOpen={props.setOpen}
		/>
	) : props.alert.alert_type === 'Aseptic CIP' ? (
		<AsepticCIPAlert
			open={
				usersList.currentUser.settings.web_notification?.receiveNotification &&
				props.open
			}
			handleClose={props.handleClose}
			alert={props.alert}
			setOpen={props.setOpen}
		/>
	) : props.alert.alert_type === 'Run Hours' ? (
		<RunHoursAlert
			open={props.open}
			handleClose={props.handleClose}
			alert={props.alert}
			setOpen={props.setOpen}
		/>
	) : props.alert.alert_type === 'Run Hours V2' || props.alert.alert_type === 'LGV Run Hours' ? (
		runHoursSubType.includes(props.alert.message_data.AlertSubType) ? (
			<RunHoursAlert
				open={
					usersList.currentUser.settings.web_notification
						?.receiveNotification && props.open
				}
				handleClose={props.handleClose}
				alert={props.alert}
				setOpen={props.setOpen}
			/>
		) : (
			<></>
		)
	) : props.alert.alert_type === 'AirLeaks UseCase' ? (
		<AirLeaksAlert
			open={
				usersList.currentUser.settings.web_notification?.receiveNotification &&
				props.open
			}
			handleClose={props.handleClose}
			alert={props.alert}
			setOpen={props.setOpen}
		/>
	) : props?.alert?.alert_type === 'downtime' ||
	  props?.alert?.usecase_name === 'downtime' ? (
		<DowmtimeAlertPopup
			open={
				usersList?.currentUser?.settings?.web_notification
					?.receiveNotification && props?.open
			}
			handleClose={props.handleClose}
			alert={props.alert}
			setOpen={props.setOpen}
		/>
	) : props?.alert?.alert_type === 'Injection Analytics' ? (
		<InjectionAnalyticsPopUp
			open={
				usersList?.currentUser?.settings?.web_notification
					?.receiveNotification && props?.open
			}
			handleClose={props.handleClose}
			alert={props.alert}
			setOpen={props.setOpen}
		/>
	) : checkTicketTypeIsLGV(props?.alert?.alert_type) ? (
		<LGVPopUp
			open={
				usersList?.currentUser?.settings?.web_notification
					?.receiveNotification && props?.open
			}
			handleClose={props.handleClose}
			alert={props.alert}
			setOpen={props.setOpen}
		/>
	) : props.alert.alert_type === 'AGR Inspection System' ? (
		<AGRAlert
			open={
				usersList.currentUser.settings.web_notification?.receiveNotification &&
				props.open
			}
			// open={props.open}
			handleClose={props.handleClose}
			alert={props.alert}
			setOpen={props.setOpen}
		/>
	) : (
		<Modal open={props.open} onClose={props.handleClose} closeAfterTransition>
			<Fade in={props.open}>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						flexDirection: 'column',
						position: 'absolute' as const,
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						width: window?.innerWidth < 400 ? '300px' : '550px',
						boxShadow: '24',
						padding: '16px 40px',
						color: 'white',
						borderRadius: '12px',
						backgroundColor:
							props.alert.health_level >= 0 && props.alert.health_level <= 2
								? RED
								: props.alert.health_level >= 3 && props.alert.health_level <= 4
									? ORANGE
									: props.alert.health_level >= 5 &&
										  props.alert.health_level <= 7
										? YELLOW
										: props.alert.health_level >= 8 &&
											  props.alert.health_level <= 10
											? GREEN
											: DEFAULT,
						border:
							props.alert.health_level >= 0 && props.alert.health_level <= 2
								? `3px solid ${RED}`
								: props.alert.health_level >= 3 && props.alert.health_level <= 4
									? `3px solid ${ORANGE}`
									: props.alert.health_level >= 5 &&
										  props.alert.health_level <= 7
										? `3px solid ${YELLOW}`
										: props.alert.health_level >= 8 &&
											  props.alert.health_level <= 10
											? `3px solid ${GREEN}`
											: `3px solid ${DEFAULT}`
					}}
				>
					<div
						style={{
							marginTop: '20px'
						}}
					>
						{props.alert.health_level >= 0 && props.alert.health_level <= 2 ? (
							<CancelIcon sx={{ fontSize: '60px' }} />
						) : props.alert.health_level >= 3 &&
						  props.alert.health_level <= 4 ? (
							<WarningIcon sx={{ fontSize: '60px' }} />
						) : props.alert.health_level >= 5 &&
						  props.alert.health_level <= 7 ? (
							<InfoIcon sx={{ fontSize: '60px' }} />
						) : props.alert.health_level >= 8 &&
						  props.alert.health_level <= 10 ? (
							<CheckCircleIcon sx={{ fontSize: '60px' }} />
						) : (
							<DisconnectedIcon />
						)}
					</div>

					<div
						style={{
							marginBottom: '5px',
							display: 'flex',
							alignItems: 'center'
						}}
					>
						<h5 style={{ fontSize: '1.2rem' }}>
							{plantsList.plants.find((plant: Plant) => {
								return plant._id === props.alert.plant_id
							}) !== undefined
								? plantsList.plants.find((plant: Plant) => {
										return plant._id === props.alert.plant_id
									}).plant_name
								: 'Plant not found'}
						</h5>
					</div>

					<div
						style={{
							width: '100%',
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between'
						}}
					>
						<div
							style={{
								backgroundColor: 'rgba(255, 255, 255, 0.7)',
								color: 'black',
								padding: '12px',
								borderRadius: '3px',
								boxShadow: '1px 1px 0px 0px #000000',
								width: '98%',
								margin: '5px'
							}}
						>
							<h5
								style={{
									fontWeight: '800',
									color: '#000',
									textAlign: 'left',
									fontSize: '12px'
								}}
							>
								Asset Name
							</h5>

							<p style={{ textAlign: 'left', fontSize: '12px' }}>
								{levelsList.levels.find((asset: Asset) => {
									return asset._id === props.alert.asset_id
								}) !== undefined
									? levelsList.levels.find((asset: Asset) => {
											return asset._id === props.alert.asset_id
										}).asset_alias ||
										levelsList.levels.find((asset: Asset) => {
											return asset._id === props.alert.asset_id
										}).asset_name
									: 'Asset Not Found'}
							</p>
						</div>

						<div
							style={{
								backgroundColor: 'rgba(255, 255, 255, 0.7)',
								color: 'black',
								padding: '12px',
								borderRadius: '3px',
								boxShadow: '1px 1px 0px 0px #000000',
								width: '98%',
								margin: '5px'
							}}
						>
							<h5
								style={{
									fontWeight: '800',
									color: '#000',
									textAlign: 'left',
									fontSize: '12px'
								}}
							>
								Health Status
							</h5>

							<p style={{ textAlign: 'left', fontSize: '12px' }}>
								{props.alert.usecase_name === 'WQMS UseCase' ||
								props.alert.usecase_name === 'Utilities CBM/PdM' ||
								props.alert.usecase_name === 'Aseptic - Process Room CCP'
									? props.alert.HLEnableFlag === false
										? 'Alarm'
										: props.alert.health_level >= 0 &&
											  props.alert.health_level <= 2
											? (props.alert.health_status = 'Danger')
											: props.alert.health_level >= 3 &&
												  props.alert.health_level <= 4
												? (props.alert.health_status = 'Alert')
												: props.alert.health_level >= 5 &&
													  props.alert.health_level <= 7
													? (props.alert.health_status = 'Monitor')
													: props.alert.health_level >= 8 &&
														  props.alert.health_level <= 10
														? (props.alert.health_status = 'Acceptable')
														: (props.alert.health_status = 'Disconnected')
									: props.alert.health_level >= 0 &&
										  props.alert.health_level <= 2
										? (props.alert.health_status = 'Danger')
										: props.alert.health_level >= 3 &&
											  props.alert.health_level <= 4
											? (props.alert.health_status = 'Alert')
											: props.alert.health_level >= 5 &&
												  props.alert.health_level <= 7
												? (props.alert.health_status = 'Monitor')
												: props.alert.health_level >= 8 &&
													  props.alert.health_level <= 10
													? (props.alert.health_status = 'Acceptable')
													: (props.alert.health_status = 'Disconnected')}
							</p>
						</div>
					</div>

					<div
						style={{
							backgroundColor: 'rgba(255, 255, 255, 0.7)',
							color: 'black',
							padding: '12px',
							borderRadius: '3px',
							boxShadow: '1px 1px 0px 0px #000000',
							width: '98%',
							margin: '5px'
						}}
					>
						<h5
							style={{
								fontWeight: '800',
								color: '#000',
								textAlign: 'center',
								fontSize: '12px'
							}}
						>
							Description
						</h5>

						<p>
							{props.alert.alert_type === 'WQMS UseCase'
								? props.alert.message_data.Description2 === undefined ||
									props.alert.message_data.Description2 === '' ||
									props.alert.message_data.Description2 === null
									? 'Not available'
									: props.alert.message_data.Description2
								: props.alert.alert_type === 'Aseptic - Valves'
									? `On  ${
											props.alert?.timestamp !== undefined &&
											props.alert?.timestamp !== null &&
											getFormatedDate(
												plantsList.plants.find((plant: any) => {
													return plant._id === props.alert.plant_id
												})?.plant_timezone,
												getTimeZoneTimeStamp(
													plantsList.plants.find((plant: any) => {
														return plant._id === props.alert.plant_id
													})?.plant_timezone,
													props.alert?.timestamp
												),
												'MM/DD/yyyy'
											)
										} at ${
											props.alert?.timestamp !== undefined &&
											props.alert?.timestamp !== null &&
											getFormatedDate(
												plantsList.plants.find((plant: any) => {
													return plant._id === props.alert.plant_id
												})?.plant_timezone,
												getTimeZoneTimeStamp(
													plantsList.plants.find((plant: any) => {
														return plant._id === props.alert.plant_id
													})?.plant_timezone,
													props.alert?.timestamp
												),
												'hh:mm A z'
											)
										}, the valve has reached out ${
											props.alert.high_limit
										} actuations.`
									: props.alert.message_data !== undefined &&
										`On  ${
											props.alert?.timestamp !== undefined &&
											props.alert?.timestamp !== null &&
											getFormatedDate(
												plantsList.plants.find((plant: any) => {
													return plant._id === props.alert.plant_id
												})?.plant_timezone,
												getTimeZoneTimeStamp(
													plantsList.plants.find((plant: any) => {
														return plant._id === props.alert.plant_id
													})?.plant_timezone,
													props.alert?.timestamp
												),
												'MM/DD/yyyy'
											)
										} at ${
											props.alert?.timestamp !== undefined &&
											props.alert?.timestamp !== null &&
											getFormatedDate(
												plantsList.plants.find((plant: any) => {
													return plant._id === props.alert.plant_id
												})?.plant_timezone,
												getTimeZoneTimeStamp(
													plantsList.plants.find((plant: any) => {
														return plant._id === props.alert.plant_id
													})?.plant_timezone,
													props.alert?.timestamp
												),
												'hh:mm A z'
											)
										}, the health level changed from ${
											props.alert.message_data?.PreviousHealth
										} to ${props.alert.message_data?.CurrentHealth}`}
						</p>
					</div>

					<div
						style={{
							width: '100%',
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between'
						}}
					>
						<button
							style={{
								color: 'black',
								backgroundColor: 'white',
								marginLeft: '2em',
								margin: '20px 5px',
								width: '45%',
								padding: '8px',
								borderRadius: '8px',
								fontWeight: 'bold'
							}}
							onClick={() => {
								props.handleClose()
							}}
							type='button'
						>
							Close
						</button>

						<button
							style={{
								color: 'black',
								backgroundColor: 'white',
								marginLeft: '2em',
								margin: '20px 5px',
								width: '45%',
								padding: '8px',
								borderRadius: '8px',
								fontWeight: 'bold'
							}}
							onClick={() => {
								props.setOpen(false)
								navigate(
									`/maintenance/ticket-details/${props.alert.ticket_id}/${props.alert.message_id}`,
									{
										replace: true
									}
								)
							}}
							disabled={ticketsList.loading || messagesList.loading}
							type='button'
						>
							{(ticketsList.loading || messagesList.loading) && (
								<CircularProgress
									sx={{
										color: 'black',
										height: '1px',
										margin: '0px 20px 0px 10px'
									}}
									size={18}
								/>
							)}
							Go to Ticket
						</button>
					</div>
				</div>
			</Fade>
		</Modal>
	)
}

export default Alert
