import moment from "moment";
import Axios from "./AxiosConfig/axiosConfigSAT";

export const loadSnackbar = (
  message = "Something went wrong!",
  type = "error"
) => ({
  type: "LOAD_SNACKBAR",
  payload: {
    message,
    type,
    open: true,
  },
});

export const defaultErrorAction = (
  message = "Something went wrong!",
  type = "error"
) => ({
  type: "LOAD_SNACKBAR",
  payload: {
    message,
    type,
    open: true,
  },
});

export const lgvHealthUseCaseRoutesCode = ["lgv-nav", "lgv-ish", "lgv-hsh"];

export const lgvHealthUseCaseList = [
  "LGV Navigation Quality",
  "LGV Hydraulic System Health",
  "LGV Inverter System Health",
];
// Round up to the next 5-minute mark
export const roundUpToNext5Minutes = (date: any) => {
  const ms = date.getTime();
  const roundedMs = Math.ceil(ms / (5 * 60 * 1000)) * (5 * 60 * 1000);
  return new Date(roundedMs);
};

// Round up to the next 1-hour mark
export const roundUpToNext1Hour = (date: any) => {
  const ms = date.getTime();
  const roundedMs = Math.ceil(ms / (60 * 60 * 1000)) * (60 * 60 * 1000);
  return new Date(roundedMs);
};

// Round up to the next 1-day mark
export const roundUpToNext1Day = (date: any) => {
  const ms = date.getTime();
  const roundedMs =
    Math.ceil(ms / (24 * 60 * 60 * 1000)) * (24 * 60 * 60 * 1000);
  return new Date(roundedMs);
};

export const getFullRangeCategories = (
  timeRange: any,
  cubeMatchingString: string
): Set<string> => {
  const categories: any = new Set();

  const aggregationValues: any = {
    FiveMinAgg: {
      interval: 5 * 60 * 1000, // 5 minutes in milliseconds
      roundFunction: roundUpToNext5Minutes,
    },
    HourlyAgg: {
      interval: 60 * 60 * 1000, // 1 hours in milliseconds
      roundFunction: roundUpToNext1Hour,
    },
    DailyAgg: {
      interval: 24 * 60 * 60 * 1000, // 1 day minutes in milliseconds
      roundFunction: roundUpToNext1Day,
    },
    minute: {
      interval: 5 * 60 * 1000, // 5 minutes in milliseconds
      roundFunction: roundUpToNext5Minutes,
    },
    hour: {
      interval: 60 * 60 * 1000, // 1 hours in milliseconds
      roundFunction: roundUpToNext1Hour,
    },
    day: {
      interval: 24 * 60 * 60 * 1000, // 1 day minutes in milliseconds
      roundFunction: roundUpToNext1Day,
    },
  };

  const startDateTime = new Date(timeRange[0] + "Z");
  const endDateTime = new Date(timeRange[1] + "Z");
  const aggregation = aggregationValues[cubeMatchingString];

  if (!aggregation) {
    throw new Error(`Cube Aggregation: ${cubeMatchingString} not supported`);
  }

  const startTime = aggregation.roundFunction(startDateTime)?.getTime();
  const endTime = endDateTime?.getTime();

  const interval = aggregation.interval;

  if (endTime && startTime) {
    for (let time = startTime; time <= endTime; time += interval) {
      categories.add(new Date(time).toISOString().slice(0, -1));
    }
  }

  return categories;
};

//function to make api call to post annotation
export const PostAnnotations = async (query: any) => {
  const response = await Axios.post("PostAnnotationToDatabricks", {
    warehouseId: "cf58c123379bb917",
    query,
  });

  return response;
};
