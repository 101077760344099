import { useRoutes } from 'react-router-dom'
import './App.css'
import ThemeProvider from './theme/ThemeProvider'
import { CssBaseline } from '@material-ui/core'
import AdapterDateFns from '@material-ui/lab/AdapterDateFns'
import LocalizationProvider from '@material-ui/lab/LocalizationProvider'
import {
	adminRoutes,
	nonAdminRoutes,
	unauthorizedRoutes,
	developerRoutes,
	supplyChainAdminRoutes
} from './routes'
import React, { useEffect, useRef, useState } from 'react'
import Alert from './componets/Alert/index'
import { env } from './env'
import { useDispatch, useSelector } from 'react-redux'
import CustomizedSnackbars from './componets/Alert/SnackBar'
import SessionTimeout from './Components/Common/SessionTimeout'
import RefreshModal from './Components/Common/RefreshModal'
import RequestAccessNotificationModal from './componets/Modal/RequestAccessNotificationModal'
import { useMsal } from '@azure/msal-react'
import cubejs from '@cubejs-client/core'
import { CubeProvider } from '@cubejs-client/react'
import { useNavigate, useLocation } from 'react-router-dom'
import Axios from './Utils/AxiosConfig/axiosConfig'
import LoadingAnimation from './componets/LoadingAnimation/index'
import { Navigate } from 'react-router-dom'
import SignalRConnection from './Services/SignalRConnection'
import { debounce, get } from 'lodash'
import { loadSnackbar } from './Utils/util'
import {
	UPDATE_LGV_LIVE_DATA
	// LOAD_PLS_LIVE_DATA
} from './Redux/Types/lgvHealth.constants'

export const AppContext: any = React.createContext(null) //creating app context to share throughout app

function App() {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const [loading, setLoading] = React.useState(true)
	const [cubeTokens, setCubeToken] = React.useState<any>([]) // state to store access tokens for all plants
	const [poolPlantFilter, setPoolPlantFilter] = React.useState<any>('')
	const [accountPermission, setaccountPermission] = React.useState<any>(null)
	const [signalRInstance, setSingnalRInstance] = React.useState<any>(null)
	const [selectedPlantForCube, setSelectedPlantForCube] =
		React.useState<any>('ALA') //default plant for fectching token
	const location = useLocation()
	const [previousPath, setPreviousPath] = useState<string>('')
	const [currentPath, setCurrentPath] = useState<string>('')

	//useeffect to handle change in selected plant throughout app
	React.useEffect(() => {
		if (selectedPlantForCube !== '' && selectedPlantForCube !== undefined) {
			//calling function to fire api call for fetching cube access token
			fetchToken()
		}
	}, [])

	const cubejsApi = React.useMemo<any>(() => {
		const cubejsApi = cubejs(
			cubeTokens.find((ele: any) => {
				// return ele.plant_code === 'RIA'
				return ele.plant_code === selectedPlantForCube
			})?.cube_access_token,
			{
				apiUrl: env.CUBE_API_URL
			}
		)

		return cubejsApi
	}, [cubeTokens, selectedPlantForCube])

	//function to make api call to fetch cube access token for all
	const fetchToken = () => {
		//fetching access token for all plants at once
		Axios.post('plants/cubeaccesstoken', {
			plant_code: 'all',
			usecase_codes: ['INJECTION', 'ALLAGR', 'TEMAGR', 'TACLGV', 'TACLGVARCH']
		}).then((res: any) => {
			setCubeToken(res.data)
			return res
		})
		setTimeout(fetchToken, 4 * 60 * 60000) //to refetch the token every 4 hours
	}

	//fetch account permissions
	React.useEffect(() => {
		if (accountPermission === null) {
			// const currentUser = usersList.users.find((user: any) => {
			//   return user.user_ad_id === userDetails;
			// });
			Axios.get('accountPermissions?pageSize=100').then((res: any) => {
				//set account permission in a role
				setaccountPermission(res.data.rows)
				return res.data.rows
			})
		}
	}, [accountPermission])

	useMsal()
	const usersList = useSelector((state: any) => state.usersList)
	const usecaseList = useSelector((state: any) => state.usecaseList)
	const [nonAdminRoutesState, setNonAdminRoutesState] = React.useState(
		nonAdminRoutes || []
	)
	const usecaseRoles = usersList.currentUserPermissions.filter((ele: any) => {
		return ele.includes('ARCH_USECASE_') || ele.includes('ARCH_SCM_')
	})

	//updating the default routes based on usecase champion role
	function usecaseChampionHandling() {
		if (
			usecaseRoles.length > 0 &&
			!usersList.currentUserPermissions.includes('ARCH_ADMIN') &&
			!usersList.currentUserPermissions.includes('ARCH_STAKEHOLDER') &&
			//handling the email-tickets link re-directing issue
			!location.pathname.includes('/maintenance/ticket-details')
		) {
			if (
				!usecaseRoles.includes('ARCH_USECASE_MOAP') &&
				!usecaseRoles.includes('ARCH_USECASE_ALL')
			) {
				nonAdminRoutes.forEach((data: any) => {
					if (data.path === '/') {
						data.children.forEach((childData: any) => {
							if (childData.path === '') {
								const usecaseRoute = window.location.href.split('/')
								const defaultUseCase =
									usecaseRoles[0].includes('ARCH_SCM_') &&
									(!usecaseRoute.some(item => item.includes('run-hours-pm')) || !usecaseRoute.some(item => item.includes('lgv-run-hours')))
										? 'DowntimeRequests'
										: usecaseRoles[0].split('_')[2]
								const filterUsecase = usecaseList.usecases.find(
									(usecaseData: any) =>
										usecaseData.level_use_code === defaultUseCase
								)
								if (
									filterUsecase &&
									filterUsecase.routes_use_code &&
									!(
										usecaseRoute.includes('downtime-request') &&
										usecaseRoute.includes('list-details')
									)
								) {
									navigate(
										filterUsecase?.level_use_code === 'EAM'
											? filterUsecase.routes_use_code
											: `/usecase/${filterUsecase.routes_use_code}`
									)
									childData.element = (
										<Navigate
											to={
												filterUsecase?.level_use_code === 'EAM'
													? filterUsecase.routes_use_code
													: `/usecase/${filterUsecase.routes_use_code}`
											}
											replace
										/>
									)
								}
							}
						})
					}
				})
			}
			if (!usecaseRoles.includes('ARCH_USECASE_ALL')) {
				const usecaseRoleName: any = usecaseRoles.map((usecaseRole: any) =>
					usecaseRole.includes('ARCH_SCM_')
						? 'DowntimeRequests'
						: usecaseRole.split('_')[2]
				)
				const filterUsecase = usecaseList.usecases.map((usecaseData: any) => {
					if (
						usecaseRoleName.includes(usecaseData.level_use_code) ||
						usecaseRoleName.includes('LGV')
					) {
						return usecaseData.routes_use_code
					}
				})
				const updatedUsecaseMenu: any = []

				nonAdminRoutes.forEach((menuItem: any) => {
					if (menuItem.path === 'usecase' && filterUsecase.length > 0) {
						const newChildren: any = []
						menuItem.children.forEach((childData: any) => {
							if (
								!childData.path.includes('/') &&
								filterUsecase.includes(childData.path)
							) {
								newChildren.push(childData)
							} else {
								const routesname = childData.path.split('/')[0]
								if (filterUsecase.includes(routesname)) {
									newChildren.push(childData)
								}
							}
						})
						menuItem.children = newChildren
					}
					updatedUsecaseMenu.push(menuItem)
				})
				//user with only cip usecase champion should not have access to alert module
				if (
					usecaseRoles.length === 1 &&
					usecaseRoles.includes('ARCH_USECASE_CIP')
				) {
					setNonAdminRoutesState(
						updatedUsecaseMenu.filter((ele: any) => {
							return ele.path !== 'maintenance'
						})
					)
				} else {
					setNonAdminRoutesState(updatedUsecaseMenu)
				}
			}
		}
	}
	React.useEffect(() => {
		if (
			usersList.currentUser !== undefined &&
			usersList.currentUserPermissions.length > 0
		) {
			usecaseChampionHandling()
		}
	}, [
		usecaseList.usecases,
		usersList.currentUser,
		usersList.currentUserPermissions
	])
	// React.useEffect(() => {
	//   if (usersList?.isPageRefresh) {
	//     setOpenRefreshModal(true);
	//   }
	// }, [usersList?.isPageRefresh]);
	const [count, setCount] = React.useState(0)
	React.useEffect(() => {
		if (
			usersList.currentUserPermissions.length > 0 &&
			usecaseList.usecases.length > 0
		) {
			if (count === 0) {
				dispatch({ type: 'LOAD_LEVELS' })
				setCount(count + 1)
			}
		}
	}, [usersList.currentUserPermissions, usecaseList.usecases])
	const contentAdmin = useRoutes(adminRoutes)
	const contentDeveloper = useRoutes(developerRoutes)
	const contentSupplyChainAdmin = useRoutes(supplyChainAdminRoutes)
	const contentNonAdmin = useRoutes(nonAdminRoutesState)
	const contentUnauthorized = useRoutes(unauthorizedRoutes)
	const seedsList = useSelector((state: any) => state.seedsList)
	const plantsList = useSelector((state: any) => state.plantsList)
	const levelsList = useSelector((state: any) => state.levelsList)
	const messagesList = useSelector((state: any) => state.messagesList)
	const ticketsList = useSelector((state: any) => state.ticketsList)
	const userpermissionsList = useSelector(
		(state: any) => state.userPermissionsList
	)
	const notificationsList = useSelector((state: any) => state.notificationsList)
	const snackBarState = useSelector((state: any) => state.snackBar)
	const [open, setOpen] = React.useState(false)
	const [flag, setFlag] = React.useState(false)
	const [openRefreshModal, setOpenRefreshModal] = React.useState(false)
	const [openRequestAccessModal, setOpenRequestAccessModal] =
		React.useState(false)
	const [alert, setAlert] = React.useState({
		alert_type: '',
		plant_id: '',
		ticket_id: '',
		asset_id: '',
		device_name: '',
		tag: '',
		tag_id: '',
		health_status: '',
		health_level: '',
		timestamp: '',
		description: ''
	})
	const userPreferencesWeb = usersList?.currentUser?.settings?.web_notification

	const disconnectSignalR = () => {
		signalRInstance
			.stop()
			.catch(() => dispatch(loadSnackbar('Error disconnecting to SignalR')))
	}

	const liveData = () => {
		signalRInstance?.on(
			'lgvLiveData',
			debounce(function (data: any) {
				dispatch({
					type: UPDATE_LGV_LIVE_DATA,
					payload: {
						data,
						plant: plantsList?.plants?.filter(
							(plant: any) =>
								plant.plant_code === selectedPlantForCube?.replace('LGV', '')
						)
					}
				})
			}, 1000)
		)

		// signalRInstance?.on('LgvPls', function (data: any) {
		// 	dispatch({
		// 		type: LOAD_PLS_LIVE_DATA,
		// 		payload: {
		// 			data
		// 		}
		// 	})
		// })
	}

	const connectToSignalR = async () => {
		const connection = SignalRConnection.connectToSignalR()
		if (signalRInstance) disconnectSignalR()

		//This method is called to create the connection
		//to SignalR so the client can receive messages
		await connection
			.start()
			.catch(() => dispatch(loadSnackbar('Error connecting to SignalR')))

		setSingnalRInstance(connection)

		connection.on('newMessage', function (msg: any) {
			handleNewMessage(msg)
			// setAlert({ show: true, message: msg });
		})

		connection.on('downtime', function (msg: any) {
			if (msg?.currentUserId?.includes(usersList.currentUser?._id)) {
				handleNewMessage(msg)
			}
			// setAlert({ show: true, message: msg });
		})

		// connection.on('lgvData', function (data: any) {
		// 	dispatch({
		// 		type: LOAD_LIVE_DATA,
		// 		payload: data
		// 	})
		// })

		// connection.on(
		// 	'lgvLiveData',
		// 	debounce(function (data: any) {
		// 		dispatch({
		// 			type: UPDATE_LGV_LIVE_DATA,
		// 			payload: { data, plant: plantsList?.plants?.filter((plant: any) => plant.plant_code === selectedPlantForCube) }
		// 		})
		// 	}, 1000)
		// )

		if (usersList.currentUser._id) {
			connection.on('allTicket', function (msg: any) {
				if (
					msg.currentUserId !== usersList.currentUser?._id &&
					msg.type === 'highlight'
				) {
					Axios.get(`users/${usersList.currentUser._id}`)
						.then((response: any) => {
							// Handle successful response (if needed)
							dispatch({
								type: 'SET_CURRENT_USER_ACTIONS',
								payload: response?.data?.user_actions
							})

							dispatch({
								type: 'LOAD_TICKETS',
								payload: {
									category: 'all_tickets',
									ticketId: '',
									useCaseId: '',
									plantId: '',
									asset_area: '',
									status: '',
									assignee: '',
									asset_alias: '',
									usecase_name: '',
									healthStatus: '',
									created_on: '',
									sort: { modified_on: -1 }
								}
							})
						})
						.catch(error =>
							dispatch(
								loadSnackbar(`Error updating Admin Ticket View: ${error}`)
							)
						)
				}
			})
		}

		if (usersList?.currentUser?.mail) {
			connection.on(
				'user-' + usersList?.currentUser?.mail,
				function (msg: any) {
					if (
						msg.email === usersList?.currentUser?.mail &&
						msg.notifyRequestAccess
					) {
						setOpenRequestAccessModal(true)
					} else if (msg.email === usersList?.currentUser?.mail) {
						setOpenRefreshModal(true)
					}
				}
			)
		}

		return () => {
			connection
				.stop()
				.catch((error: Error) =>
					dispatch(loadSnackbar(`Error stopping connection: ${error}`))
				)
		}
	}

	React.useEffect(() => {
		if(seedsList.seeds){
		  const loggerSeed = seedsList.seeds?.find(
			  (ele: any) => ele.seed_key_name === 'Cube Logger'
			)?.seed_data_value
		  if(loggerSeed !== undefined){
			localStorage.setItem('loggerSeed', JSON.stringify(loggerSeed));
		  }
		}
	  }, [seedsList.seeds])


	React.useEffect(() => {
		if (!location.pathname?.includes('injection-analytics')) {
			sessionStorage.removeItem('Injection Config')
		}
		setPreviousPath(currentPath)
		setCurrentPath(location.pathname)
	}, [location.pathname])
	useEffect(() => {
		if (
			!['/maintenance/tickets', '/maintenance/ticket-details'].some(
				(item: string) => currentPath.includes(item)
			)
		) {
			dispatch({ type: 'SET_CURRENT_USECASE', payload: '' })
		}
	}, [currentPath])

	React.useEffect(() => {
		/*
	const checkList = () => {
	  if (levelsList.levels.length !== 0) {
		setLoading(false);
		return;
	  }
	  setTimeout(checkList, 250);
	};
	*/
		//setTimeout(checkList, 250);
		const hideAnimation = () => {
			setLoading(false)
			return
		}
		setTimeout(hideAnimation, 4000)
		return
	}, [levelsList.levels.length])

	React.useEffect(() => {
		if (
			usersList.currentUser !== undefined &&
			usersList.currentUserPermissions.length > 0 &&
			usecaseList.usecases.length > 0
		) {
			connectToSignalR()
		}
	}, [
		usersList.currentUser,
		usersList.currentUserPermissions,
		usecaseList.usecases
	])

	useEffect(() => {
		liveData()
	}, [signalRInstance, selectedPlantForCube])

	//use effect to fetch and handle unread messages and opening application
	React.useEffect(() => {
		if (
			messagesList.messages.length > 0 &&
			usersList.currentUser.user_ad_id !== undefined
		) {
			//fetching messages which are not read by current user
			const unreadNotifications = messagesList.messages
				.filter((message: any) => {
					return usersList.currentUser.last_msg_read_at === undefined
						? true
						: new Date(message?.timestamp).getTime() >
								new Date(usersList.currentUser.last_msg_read_at).getTime() &&
								notificationsList.notifications.every((ele: any) => {
									return ele.message_id !== message._id
								})
				})
				.sort((a: any, b: any) => {
					return a?.timestamp > b?.timestamp
						? 1
						: a?.timestamp < b?.timestamp
							? -1
							: 0
				})
			//dispatching redux call to handle new notifications
			if (unreadNotifications.length > 0 && flag === false) {
				unreadNotifications.forEach((msg: any) => {
					return dispatch({
						type: 'ADD_NOTIFICATION',
						payload: { ...msg, read: false }
					})
				})
				setFlag(true)
				//  axios call to update message read by current user
				Axios.put(`users/${usersList.currentUser._id}`, {
					last_msg_read_at:
						unreadNotifications[unreadNotifications.length - 1]?.timestamp
				})
			}
		}
	}, [usersList.currentUser, messagesList.messages])

	const handleWebNotification = (msg: any) => {
		if (userPreferencesWeb?.receiveNotification) {
			if (userPreferencesWeb?.healthStatus === 'All') {
				setOpen(true)
			} else if (
				userPreferencesWeb?.healthStatus === 'AlertAndDanger' &&
				msg.health_level <= 7 &&
				msg.health_level >= 2
			) {
				setOpen(true)
			} else if (
				userPreferencesWeb?.healthStatus === 'Danger' &&
				msg.health_level <= 2 &&
				msg.health_level >= 2
			) {
				setOpen(true)
			}
		}
	}

	const handleNewMessage = (msg: any) => {
		let isDispatchRequired = false
		const plantRoles = usersList.currentUserPermissions
			.filter((ele: any) => {
				return ele.includes('ARCH_PM_')
			})
			.map((ele: any) => {
				return ele.substring(8)
			})
		const alertPlantCode = msg.plant_code || msg.message_data.FacilityCode
		let isAssetAllowed = false
		if (
			usersList.currentUser.accessible_assets &&
			usersList.currentUser.accessible_assets.length > 0
		) {
			isAssetAllowed =
				usersList.currentUser.accessible_assets
					.map((ele: any) => {
						return ele.assets.concat(ele.parent_asset)
					})
					.flat()
					.indexOf(msg.asset_id) !== -1
		}
		if (
			usersList.currentUserPermissions.includes('ARCH_ADMIN') ||
			usersList.currentUserPermissions.includes('ARCH_STAKEHOLDER')
		) {
			isDispatchRequired = true
			msg.read = false
			setOpen(false)
			dispatch({ type: 'ADD_NOTIFICATION', payload: msg })
			//  axios call to update message read by current user
			Axios.put(`users/${usersList.currentUser._id}`, {
				last_msg_read_at: msg?.timestamp
			})
			//replace with actual message data received
			setAlert(msg)
			handleWebNotification(msg)
		} else if (isAssetAllowed && usecaseRoles.length === 0) {
			isDispatchRequired = true
			msg.read = false
			setOpen(false)
			dispatch({ type: 'ADD_NOTIFICATION', payload: msg })
			//  axios call to update message read by current user
			Axios.put(`users/${usersList.currentUser._id}`, {
				last_msg_read_at: msg?.timestamp
			})
			//replace with actual message data received
			setAlert(msg)
			handleWebNotification(msg)
		} else {
			if (alertPlantCode !== undefined && usecaseRoles.length === 0) {
				if (plantRoles.includes(alertPlantCode)) {
					isDispatchRequired = true
					msg.read = false
					setOpen(false)
					dispatch({ type: 'ADD_NOTIFICATION', payload: msg })
					//  axios call to update message read by current user
					Axios.put(`users/${usersList.currentUser._id}`, {
						last_msg_read_at: msg?.timestamp
					})
					//replace with actual message data received
					setAlert(msg)
					handleWebNotification(msg)
				}
			}
			if (usecaseRoles.length > 0) {
				const PlantRoleFlag = usersList.currentUserPermissions.some(
					(ele: any) => {
						return ele.includes('ARCH_PM_')
					}
				)
				let isAlertEnabledFalg = false
				const usecaseCode = usecaseRoles.map((item: any) => item.split('_')[2])
				const usecaseNames = usecaseList?.usecases.map((item: any) => {
					if (usecaseCode.includes(item.level_use_code)) {
						return item.litmus_use_code
					}
				})
				const isUseCaseAllowed =
					usecaseNames.includes(msg.usecase_name) ||
					usersList.currentUserPermissions.includes('ARCH_USECASE_ALL')
				//only usecase champion role
				if (
					!PlantRoleFlag &&
					usersList.currentUser.accessible_assets.length === 0
				) {
					if (isUseCaseAllowed) {
						isAlertEnabledFalg = true
					}
				} else {
					//usecase champion and plant manager
					if (
						plantRoles.includes(alertPlantCode) &&
						isUseCaseAllowed &&
						usersList.currentUser.accessible_assets.length === 0
					) {
						isAlertEnabledFalg = true
					}
					//usecase champion and asset manager
					else if (
						isAssetAllowed &&
						isUseCaseAllowed &&
						plantRoles.length === 0
					) {
						isAlertEnabledFalg = true
					}
					//usecase champion witn asset manager and plant manager
					else {
						if (
							(isAssetAllowed || plantRoles.includes(alertPlantCode)) &&
							isUseCaseAllowed
						) {
							isAlertEnabledFalg = true
						}
					}
				}
				if (isAlertEnabledFalg) {
					isDispatchRequired = true
					msg.read = false
					setOpen(false)
					dispatch({ type: 'ADD_NOTIFICATION', payload: msg })
					//  axios call to update message read by current user
					Axios.put(`users/${usersList.currentUser._id}`, {
						last_msg_read_at: msg?.timestamp
					})
					//replace with actual message data received
					setAlert(msg)
					handleWebNotification(msg)
				}
			}
		}
		if (isDispatchRequired) {
			//dispatch({ type: "LOAD_TICKETS" });
			// dispatch({ type: "LOAD_MESSAGES" });
			dispatch({ type: 'UPDATE_TICKETS', payload: msg.ticket_id })
			// dispatch({ type: "ADD_MESSAGE", payload: msg.message_id });
		}
	}
	const handleClose = () => setOpen(false)
	const useDidMountEffect = (func: any, deps: any) => {
		const didMount = useRef(false)
		React.useEffect(() => {
			if (didMount.current) func()
			else didMount.current = true
		}, deps)
	}
	useDidMountEffect(() => {
		dispatch({ type: 'LOAD_TICKETS', payload: ticketsList.filterParam })
	}, [ticketsList.alertStatus])
	React.useEffect(() => {
		if (
			Object.keys(usersList.currentUser).length &&
			userpermissionsList.userPermissions.length > 0 &&
			userpermissionsList.loading === false
		) {
			if (usersList.currentUser) {
				const currentUser = usersList.currentUser

				dispatch({
					type: 'SET_CURRENT_USER_PERMISSIONS',
					payload: currentUser.user_arch_group.map((userGroupId: any) => {
						return userpermissionsList.userPermissions.find(
							(userPermission: any) => {
								return userPermission._id === userGroupId
							}
						).role_name
					})
				})
				dispatch({ type: 'LOAD_USECASE' })
				dispatch({
					type: 'SET_CURRENT_ACCOUNT_PERMISSIONS',
					payload: currentUser.user_account_group?.map((userAccountId: any) => {
						return accountPermission?.find((PermissionData: any) => {
							return PermissionData._id === userAccountId
						})
					})
				})
				// dispatch({ type: "LOAD_ALL_USERS_IMAGE" });

				if (
					currentUser?.mobile_phone === null ||
					!currentUser?.mobile_phone.trim().startsWith('+')
				) {
					dispatch({
						type: 'LOAD_BANNER',
						payload: {
							message: 'Please save phone number with country code',
							type: 'info',
							open: true
						}
					})
				}
			}
		}
	}, [usersList.currentUser, userpermissionsList.userPermissions.length])

	React.useEffect(() => {
		if (
			usersList.currentUserPermissions.includes('ARCH_ADMIN') ||
			usersList.currentUserPermissions.includes('ARCH_STAKEHOLDER') ||
			usersList.currentUserPermissions.includes('ARCH_SCM_ADMIN') ||
			usersList.currentUserPermissions.includes('ARCH_DEVELOPER')
		) {
			const accessiblePlantsArray = plantsList.plants
			dispatch({
				type: 'SET_ACCESSIBLE_PLANTS',
				payload: accessiblePlantsArray
			})
		} else if (
			levelsList.levels.length !== 0 &&
			levelsList.loading === false &&
			plantsList.plants.length !== 0 &&
			plantsList.loading === false
		) {
			let plantRoles = []
			let accessiblePlantsArray: any = []

			if (
				usersList.currentUserPermissions.some((ele: any) => {
					return ele.includes('ARCH_PM_')
				}) //condition for user having plant manager role
			) {
				plantRoles = usersList.currentUserPermissions.filter((ele: any) => {
					return ele.includes('ARCH_PM_')
				})
				accessiblePlantsArray = plantRoles
					.map((ele: any) => {
						return ele.substring(8)
					})
					.map((plantCode: any) => {
						return plantsList.plants.find((plant: any) => {
							return plant.plant_code === plantCode
						})
					})
			}
			if (usersList.currentUserPermissions.includes('ARCH_ASSET_MANAGER')) {
				//condition for checking user having asset manager role
				const accessible_assets = usersList.currentUser.accessible_assets
					.map((ele: any) => {
						return ele.assets.concat(ele.parent_asset)
					})
					.flat()
					.filter(function (value: any, index: any, array: any) {
						return array.indexOf(value) === index
					})
				const plantIds = accessible_assets?.map((allowedAssetId: any) => {
					return levelsList.levels.find((asset: any) => {
						return asset._id === allowedAssetId
					})?.plant_id
				})
				const uniquePlantIds = plantIds?.filter((c: any, index: any) => {
					return plantIds.indexOf(c) === index
				})
				const accessiblePlantsArrayIDs = accessiblePlantsArray.map(
					(item: any) => item._id
				)
				accessiblePlantsArray = accessiblePlantsArray.concat(
					uniquePlantIds.map((accessiblePlantId: any) => {
						return plantsList.plants.find((plant: any) => {
							return (
								plant._id === accessiblePlantId &&
								!accessiblePlantsArrayIDs.includes(plant._id)
							)
						})
					})
				)
			}
			//handling for usecase champion role
			if (
				!usersList.currentUserPermissions.some((ele: any) => {
					return ele.includes('ARCH_PM_')
				}) &&
				!usersList.currentUserPermissions.includes('ARCH_ASSET_MANAGER')
			) {
				accessiblePlantsArray = plantsList.plants
			}
			dispatch({
				type: 'SET_ACCESSIBLE_PLANTS',
				payload: accessiblePlantsArray.filter((ele: any) => {
					return ele !== undefined
				})
			})
		}
	}, [
		levelsList.levels.length,
		usersList.currentUserPermissions,
		plantsList.plants
	])

	React.useEffect(() => {
		dispatch({ type: 'LOAD_USER_ACCESS' })
		dispatch({ type: 'LOAD_PLANTS' })
		dispatch({ type: 'LOAD_USER_PERMISSIONS' })
		dispatch({ type: 'LOAD_PENDING_APPROVAL_USERS' })
		dispatch({ type: 'LOAD_USECASE' })
		//dispatch({ type: "LOAD_LEVELS" });
		dispatch({ type: 'LOAD_ASSET_TYPES' })
		dispatch({ type: 'LOAD_ASSETS' })
		dispatch({ type: 'LOAD_SENSORS' })
		dispatch({ type: 'LOAD_DEVICES' })
		dispatch({ type: 'LOAD_KPIS' })
		dispatch({ type: 'LOAD_DASHBOARDS' })
		dispatch({ type: 'LOAD_VISUALS' })
		dispatch({ type: 'LOAD_ALIAS' })
		dispatch({ type: 'LOAD_LOCATIONS' })
		dispatch({ type: 'LOAD_OEM' })
		dispatch({ type: 'LOAD_MODELS' })
		// dispatch({ type: "LOAD_CHATROOMS" });
		dispatch({ type: 'LOAD_SEEDS' })
		dispatch({ type: 'LOAD_SATANNOTATIONS' })
		dispatch({ type: 'LOAD_SATPLANTS' })
		dispatch({ type: 'LOAD_SAT_DETAILS' })
		dispatch({ type: 'LOAD_USERS' })
		// dispatch({ type: 'SET_SCM_USER_PLANT' })
	}, [])

	React.useEffect(() => {
		const handleLogin = async () => {
			await Axios.post('usecase_activity_logs/insertUseCaseEventLogs', {
				user_id: usersList.currentUser.mail,
				usecase_type: 'login',
				user_name: usersList.currentUser.display_name
			})
		}
		if (get(usersList, 'currentUser.mail', null)) {
			handleLogin()
		}
	}, [usersList.currentUser])

	return (
		<>
			<ThemeProvider>
				<AppContext.Provider
					value={{
						accountPermission,
						selectedPlantForCube,
						setSelectedPlantForCube,
						poolPlantFilter,
						setPoolPlantFilter,
						signalRInstance,
						setSingnalRInstance
					}}
				>
					<CubeProvider cubejsApi={cubejsApi}>
						<LocalizationProvider dateAdapter={AdapterDateFns}>
							<CssBaseline />
							{usersList.currentUserPermissions === undefined ||
							userpermissionsList.loading === true ||
							!usersList.accessiblePlants.length ||
							loading === true ? (
								<LoadingAnimation />
							) : usersList.currentUserPermissions.includes('ARCH_ADMIN') ? (
								contentAdmin
							) : usersList.currentUserPermissions.includes(
									'ARCH_DEVELOPER'
							  ) ? (
								contentDeveloper
							) : usersList.currentUserPermissions.includes(
									'ARCH_SCM_ADMIN'
							  ) ? (
								contentSupplyChainAdmin
							) : usersList.currentUserPermissions.includes(
									'ARCH_STAKEHOLDER'
							  ) ||
							  usersList.currentUserPermissions.includes(
									'ARCH_ASSET_MANAGER'
							  ) ||
							  usersList.currentUserPermissions.some((ele: any) => {
									return ele.includes('ARCH_PM_')
							  }) ||
							  usersList.currentUserPermissions.some((ele: any) => {
									return ele.includes('ARCH_USECASE_')
							  }) ||
							  usersList.currentUserPermissions.some((ele: any) => {
									return ele.includes('ARCH_USECASE_SAT')
							  }) ||
							  usersList.currentUserPermissions.some((ele: any) => {
									return ele.includes('ARCH_SCM_')
							  }) ||
							  usersList.currentUserPermissions.some((ele: any) => {
									return ele.includes('ARCH_SAT_LEAD')
							  }) ? (
								contentNonAdmin
							) : (
								contentUnauthorized
							)}
						</LocalizationProvider>

						<Alert
							open={open}
							handleClose={handleClose}
							alert={alert}
							setOpen={setOpen}
						/>

						<CustomizedSnackbars
							open={snackBarState.open}
							message={snackBarState.message}
							type={snackBarState.type}
						/>
						{env.environment === 'PROD' || env.environment === 'UAT' ? (
							<SessionTimeout />
						) : (
							<></>
						)}
						<RefreshModal
							isAlertOpen={openRefreshModal}
							setAlertClose={() => setOpenRefreshModal(false)}
						/>
						<RequestAccessNotificationModal
							open={openRequestAccessModal}
							handleClose={() => setOpenRequestAccessModal(false)}
						/>
					</CubeProvider>
				</AppContext.Provider>
			</ThemeProvider>
		</>
	)
}

export default App

